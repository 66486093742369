@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.textBoxPrincipalBanner{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.15);  ;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}
.instagramCards{

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.30);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

input::placeholder{
  color: #FFFFFF;
  font-family: 'Poppins';
}


  .custom-shape-divider-bottom-1695183898 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: 0;
}

.custom-shape-divider-bottom-1695183898 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 180px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1695183898 .shape-fill {
    fill: #FFFFFF;
}
  .custom-shape-divider-bottomContact-1695183898 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotateX(180deg);
    z-index: 0;
}

.custom-shape-divider-bottomContact-1695183898 svg {
    position: relative;
    display: block;
    width: calc(120% + 1.3px);
    height: 15rem;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottomContact-1695183898 .shape-fill {
    fill: #75ad39fd;
} 
  .custom-shape-divider-bottomSliderWave-1695183898 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotateX(180deg);
    z-index: 0;
}

.custom-shape-divider-bottomSliderWave-1695183898 svg {
    position: relative;
    display: block;
    width: calc(120% + 1.3px);
    height: 14rem;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottomSliderWave-1695183898 .shape-fill {
    fill: #fff;
} 
  .custom-shape-divider-bottomInstagram-1695183898 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotateX(0deg);
    z-index: 0;
}

.custom-shape-divider-bottomInstagram-1695183898 svg {
    position: relative;
    display: block;
    width: calc(110% + 1.3px);
    height: 12rem;
    transform: rotateY(0deg);
}

.custom-shape-divider-bottomInstagram-1695183898 .shape-fill {
    fill: #323A73;
} 

.custom-shape-divider-bottom2-1695183898 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotateX(180deg);
}

.custom-shape-divider-bottom2-1695183898 svg {
  position: relative;
  display: block;
  width: calc(100% + 710.3px);
  height: 203px;
  transform: rotateY(0deg);
}

.custom-shape-divider-bottom2-1695183898 .shape-fill {
  fill: #323A73;
}

.custom-shape-divider-bottom3-1695183898 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom3-1695183898 svg {
  position: relative;
  display: block;
  width: calc(121% + 10.3px);
  height: 302px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom3-1695183898 .shape-fill {
  fill: #FFFFFF;
}


.custom-shape-divider-bottom4-1695183898 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotateX(180deg);
}

.custom-shape-divider-bottom4-1695183898 svg {
  position: relative;
  display: block;
  width: calc(115% + 106.3px);
  height: 303px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom4-1695183898 .shape-fill {
  fill: #FFFFFF;
}






.custom-shape-divider-bottomSliderThree-1696601789 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottomSliderThree-1696601789 svg {
  position: relative;
    display: block;
    width: calc(134% + 4.3px);
    height: 462px;
}

.custom-shape-divider-bottomSliderThree-1696601789 .shape-fill {
  fill: #FFFFFF;
}
.custom-shape-divider-bottomSliderFour-1696601789 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottomSliderFour-1696601789 svg {
  position: relative;
    display: block;
    width: calc(151% + 0.1px);
    height: 452px;
}

.custom-shape-divider-bottomSliderFour-1696601789 .shape-fill {
  fill: #FFFFFF;
}

/* ----------------------------- Top waves ---------------------------- */
.custom-shape-divider-top-slider-1695183898 {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotateX(180deg);
}

.custom-shape-divider-top-slider-1695183898 svg {
  position: relative;
  display: block;
  width: calc(141% + -10rem);
  height: 266px;
  transform: rotateY(0deg);

}


.custom-shape-divider-top-slider2-1695183898 {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotateX(180deg);
}

.custom-shape-divider-top-slider2-1695183898 svg {
  position: relative;
  display: block;
  width: calc(114% + 710.3px);
  height: 203px;
  transform: rotateY(0deg); 

}

.custom-shape-divider-top-slider3-1695183898 {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotateX(180deg);
}

.custom-shape-divider-top-slider3-1695183898 svg {
  position: relative;
  display: block;
  width: calc(114% + 710.3px);
  height: 203px;
  transform: rotateY(0deg); 

}
.custom-shape-divider-top-slider4-1695183898 {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotateX(180deg);
}

.custom-shape-divider-top-slider4-1695183898 svg {
  position: relative;
  display: block;
  width: calc(114% + 710.3px);
  height: 203px;
  transform: rotateY(0deg); 

}

.bg-position{
  background-position: center top 27rem;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}