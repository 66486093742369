@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

p {
  cursor: pointer;
}

.borderColor {
  border-radius: 24px;
  border: 5px solid rgba(129, 194, 61, 0.00);
  background: var(--White, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(50, 58, 115, 0.25);
}

.fixed-btn {
  z-index: 10;
  position: fixed; 
  bottom: 10%;
  right: 3%;
  color: #FFF;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.borderColorServices {
  box-sizing: border-box;
  background: linear-gradient(#ffffffc9, #ffffffa8) 50% 50% / calc(100% - 30px) calc(100% - 30px) no-repeat,
    radial-gradient(at 57% 95%,
      transparent 0%,
      rgba(72, 171, 224, 0.027) 59%,
      #81c23d 96%) 85% 0;
  border-radius: 1.9rem;
  padding: 14px;
  box-shadow: inset 0px 0px 0px 10px rgba(255, 255, 255, 1);
  /* Ajusta el tamaño del sombreado interno según tus necesidades */

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}

.borderColorServices::before {
  content: "";
  position: absolute;
  top: 10px;
  /* Ajusta la posición según tus necesidades */
  left: 10px;
  /* Ajusta la posición según tus necesidades */
  right: 10px;
  /* Ajusta la posición según tus necesidades */
  bottom: 10px;
  /* Ajusta la posición según tus necesidades */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), transparent);
  /* Degradado hacia abajo */
  border-radius: 1.2rem;
  /* Radio de borde del sombreado */
  box-shadow: inset -4px 0px 0px 10px rgba(255, 255, 255, 1);
  /* Tamaño del sombreado interno */
  z-index: -1;
  /* Asegura que esté detrás del contenido */
}

.boxShadowelementsServices {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.608);
}

/* heart beat  */

@keyframes heartbeat {
  0% {
    transform: scale(0.877777);
  }

  8%{
    transform: scale(0.9);
  }

  25% {
    transform: scale(0.933333);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.933333);
  }

  91%{
    transform: scale(0.9);
  }


  100% {
    transform: scale(0.877777);
  }
}

.heartbeat {
  animation: heartbeat 9s infinite;
  animation-delay: 9s;
}



/* allies component */


.alliesButtons {
  border-radius: 48px 48px 0px 48px;
  border: 2px solid #FFF;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.30);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.alliesButtonslayout {
  display: flex;
  width: 16.875rem;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.alliesButtonslayoutResponsive {
  display: flex;
  width: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.alliesButtonsWellness {
  border-radius: 48px 0px 48px 48px;
  border: 2px solid #FFF;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.30);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.alliesButtonsLeaders {
  border-radius: 48px 48px 48px 0px;
  border: 2px solid #FFF;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.30);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.alliesButtonsHeart {
  border-radius: 0px 48px 48px 48px;
  border: 2px solid #FFF;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.30);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}


.group {
  transform-origin: bottom;
  transition: all 0.6s ease;
  /* Propiedad de transición */
}

.group:hover {

  margin-top: 2rem;
  transform: scaleY(1.1);
  opacity: 0.9;
}


/* users comments */

.boxShadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}